(function( $ ) {
  "use strict";
  var D = $.fn.drag = function(){
    try {
      D.open.apply(this, arguments);
    } catch (e) {}
  };
  $.extend(D, {
    block : {},
    elem : {},
    objs: {
      table: {type:"table", position:""},
      column:{type:"table", position:""},
      pie: {type:"table", position:""},
      line: {type:"table", position:""},
      bar:{type:"table", position:""}
    },
    isadd : false,
    topAdded : false,
    bottomAdded : false,
    removed : false,
    ang : {},
    template : "<table class='block'><tr><td class='dragActive left' droppable='true' dropped='false'></td><td class='dragActive right' droppable='true' dropped='false'></td></tr><tr><td colspan=2 class='dragActive bottom' droppable='true' dropped='false'></td></tr></table>",
    // <div class='drop_50 dragActive' droppable='true' dropped='false'></div><div class='drop_50 dragActive' droppable='true' dropped='false'></div><div class='drop_100 dragActive' droppable='true' dropped='false'></div></div>
    init : function(){
      D.block.find('.droptarget').each(function(){
        $(this).attr('draggable', true).attr('clone', 'true').on('dragstart', function(){D.start($(this))}).on('dragend', function(){D.end()}).on('drag',function() {D.drag(event)});
      });
      D.block.find('td[droppable=true]').each(function(){
        $(this).on('dragover', false).on('drop', function(){D.drop(event, $(this))}).attr('dropped', false).on('dragover', function(){D.allow(event, $(this))});
      });
    },
    start : function(dom){
      D.elem = dom;
      if(dom.parent().attr('droppable') === 'true') {
        dom.parent().attr('dropped', 'false');
      }
      if(dom.attr('clone') === 'true') {
        var d = $(D.template);
        D.block.find('#dropable_fields').append(d);
      }
      D.block.find('td[droppable=true]').each(function(){
        $(this).on('dragover', false).on('drop', function(){D.drop(event, $(this))}).on('dragover', function(){D.allow(event, $(this))});
      });
      D.block.find('.drop_50,.drop_100').addClass('dragActive');
      D.block.find('.dragPassive').addClass('dragActive').removeClass('dragPassive');
    },
    drag: function(evt) {
      D.block.find('.activeClass').removeClass('activeClass')
    },
    end : function(){
      if(D.elem.parent().attr('dropped') === 'false' && !D.removed) {
        D.elem.parent().attr('dropped', 'true');
      }
      D.block.find('.block').each(function(){
        var ch = 0;
        $(this).find('td[droppable=true]').each(function(){
          if($(this).attr('dropped') === 'true') ch++;
        });
        if(ch === 0) $(this).remove();
      });
      D.block.find('.block').each(function(){
        var ch = 0, d = [];
        $(this).find('.left, .right').each(function(){
          if($(this).attr('dropped') === 'true') ch++;
          d.push($(this));
        });
        if(ch === 0) d.forEach(function(dom){
          dom.remove();
        });
      });
      D.block.find('.bottom').each(function(idx){
        if($(this).attr('dropped') === 'false')
          $(this).remove();
      });
      D.block.find('td[droppable=true]').each(function(){
        if($(this).attr('dropped') === 'false')
          $(this).removeClass('dragActive').addClass('dragPassive');
      });
      D.block.find('.activeClass').removeClass('activeClass');
      // Save in angular
      if(D.isadd){
        if (D.elem.hasClass('tb')) {
          D.elem.data('block', {type:"table",chart_type:'',position:"",source:0,name:'',name_css:{'font-family':'arial', 'font-size':'14px', 'text-align':'center', 'background-color':'transparent', 'color':'#000000','font-weight':'normal', 'font-style':'normal','text-decoration':'none'}, total_css:{'font-family':'arial', 'font-size':'14px', 'text-align':'center', 'background-color':'transparent', 'color':'#000000','font-weight':'bold', 'font-style':'normal','text-decoration':'none'}, cols:[], group:{type:'', by:[], cols:[]}});
        }
        else if (D.elem.hasClass('column')) {
          D.elem.data('block', {type:'charts',chart_type:'column',position:'',source:0,xTitle:'',yTitle:'',categoryAxis:'',height:600,name:'',lines:[]});
        }
        else if (D.elem.hasClass('pie')) {
          D.elem.data('block', {type:'charts',chart_type:'pie',position:'',source:0,name:'',pie_type:'pie',col:'', val:'',group:'sum',height:600});
        }
        else if (D.elem.hasClass('line')) {
          D.elem.data('block', {type:'charts',chart_type:'line',position:'',source:0,xTitle:'',yTitle:'',categoryAxis:'',height:600,name:'',lines:[]});
        }
        else if (D.elem.hasClass('bar')) {
          D.elem.data('block', {type:'charts',chart_type:'bar',position:'',source:0,xTitle:'',yTitle:'',categoryAxis:'',height:600,name:'',lines:[]});
        }
        var dd = D.report();
        setTimeout(function(){
          if (D.elem.hasClass('tb')) {
            D.ang.addItem('table', dd);
          } else if (D.elem.hasClass('column')) {
            D.ang.addItmCharts('column', dd);
          } else if (D.elem.hasClass('pie')) {
            D.ang.addItmCharts('pie', dd);
          } else if (D.elem.hasClass('line')) {
            D.ang.addItmCharts('line', dd);
          } else if (D.elem.hasClass('bar')) {
            D.ang.addItmCharts('bar', dd);
          }
          D.topAdded = false;
          D.bottomAdded = false;
          D.removed = false;
          D.elem = {};
          D.isadd = false;
        }, 400);
      } else {
        D.report();
        D.topAdded = false;
        D.bottomAdded = false;
        D.removed = false;
        D.elem = {};
        D.isadd = false;
      }
    },
    report : function(){
      var rows = [], rowId, idx;
      D.block.find('.block').each(function(row_id) {
        var row = {type:'', blocks:[]};
        var blocks = [];
        var id = 0;
        $(this).find('.left, .bottom').each(function(){
          row.type = $(this).hasClass('bottom') ? 100 : 50;
          blocks = [];
          if($(this).attr('dropped') === 'true') {
            var bl = $(this).find('div:first').data('block');
            if(bl !== undefined) {
              if ($(this).find('div:first').is(D.elem)) {
                rowId = row_id;
                idx = id;
              }
              id++;
              if ($(this).hasClass('left'))
                bl.position = "left";
              if ($(this).hasClass('bottom'))
                bl.position = "bottom";
              $(this).find('div:first').data('block', bl);
              blocks.push(bl);
            }
          }
          if($(this).hasClass('left') && $(this).parent().find('.right').attr('dropped') === 'true') {
            var sl = $(this).parent().find('.right').find('div:first').data('block');
            if(sl !== undefined) {
              if ($(this).parent().find('.right').find('div:first').is(D.elem)) {
                rowId = row_id;
                idx = id;
              }
              id++;
              sl.position = "right";
              $(this).parent().find('.right').find('div:first').data('block', sl);
              blocks.push(sl);
            }
          }
        });
        if(blocks.length > 0) {
          row.blocks = blocks;
          rows.push(row);
        }
      });
      D.isadd = false;
      D.ang.saveRows(rows);
      return {row_id:rowId, idx:idx};
    },
    allow : function(evt, de) {
      de.addClass('activeClass');
      if(D.elem.attr('clone') === 'false' && de.is(D.elem.parent())) {
        if((de.get(0).getBoundingClientRect().top + de.height() / 2)  > evt.pageY) {
          if(!D.topAdded) {
            D.block.find('.block').each(function(){
              var ch = 0;
              $(this).find('td[droppable=true]').each(function(){
                if($(this).attr('dropped') === 'true' || $(this).is(D.elem.parent())) ch++;
              });
              if(ch === 0) {
                $(this).remove();
              }
            });
            de.parent().parent().parent().before($(D.template));
            D.block.find('td[droppable=true]').each(function(){
              $(this).on('dragover', false).on('drop', function(){D.drop(event, $(this))}).on('dragover', function(){D.allow(event, $(this))});
            });
            D.topAdded = true;
            D.bottomAdded = false;
          }
        } else {
          if(!D.bottomAdded) {
            D.block.find('.block').each(function(){
              var ch = 0;
              $(this).find('td[droppable=true]').each(function(){
                if($(this).attr('dropped') === 'true' || $(this).is(D.elem.parent())) ch++;
              });
              if(ch === 0) $(this).remove();
            });
            var dd = $(D.template);
            de.parent().parent().parent().after(dd);
            D.block.find('td[droppable=true]').each(function(){
              $(this).on('dragover', false).on('drop', function(){D.drop(event, $(this))}).on('dragover', function(){D.allow(event, $(this))});
            });
            D.bottomAdded = true;
            D.topAdded = false;
          }
        }
      }
    },
    remove : function(dom){
      D.removed = true;
      D.elem = dom.parent();
      dom.parent().parent().attr('dropped', 'false');
      dom.parent().fadeOut('slow',function () {$(this.remove(dom))});
      D.end();
    },
    edit : function(dom){
      var rowId = 0, idx = -1;
      D.block.find('.block').each(function(){
        if($(this).find('.bottom').find('div:first').is(dom)) {
          idx = 0;
        }
        var dd = 0;
        $(this).find('.left,.right').each(function(){
          if($(this).hasClass('left')) {
            if($(this).find('div:first').is(dom)) {
              idx = 0;
            } else {
              if ($(this).attr('dropped') === 'true')
                dd++;
            }
          }
          if($(this).hasClass('right')) {
            if($(this).find('div:first').is(dom)) {
              idx = dd > 0 ? 1 : 0;
            }
          }
        });
        if (idx === -1)
          rowId = rowId + 1;
      });
      D.ang.editItem(rowId, idx);
    },
    drop : function(evt, de){
      if(de.attr('dropped') === 'true') {
        evt.preventDefault();
      } else {
        if(de.attr('droppable') === 'true')  {
          if(D.elem.attr('clone') === 'true') {
            D.elem = D.elem.clone(true);
            D.elem.attr('clone', 'false');
            var cb = $('<button title ="del" class="material-icons" style="color:var(--black-white)">remove</button>');
            cb.on('click', function(){D.remove($(this))});
            D.elem.append(cb);
            D.isadd = true;
            D.elem.on('dblclick', function () {D.edit($(this))});
            D.elem.removeClass('droptarget').addClass('dropped');
            de.append(D.elem);
            // D.block.find('.block').each(function() {
            //   $(this).find('.left, .right, .bottom').each(function(){
            //   });
            // });
            D.elem.find('button').removeClass('close_btn_100').removeClass('close_btn_50').addClass(de.hasClass('bottom') ? 'close_btn_100' : 'close_btn_50');
            de.attr('dropped', 'true');
          } else {
            de.append(D.elem);
            D.elem.find('button').removeClass('close_btn_100').removeClass('close_btn_50').addClass(de.hasClass('bottom') ? 'close_btn_100' : 'close_btn_50');
            de.attr('dropped', 'true');
          }
        }
      }
    }
  });
  $.fn.drag = function(ang) {
    if (D.block != null) {
      D.block = $(this);
      D.ang = ang;
      D.init();
    }
    return this;
  };
  $.fn.draw = function (ang) {
    //try {
      var dd = D.block.find('#dropable_fields');
      dd.html('');
      ang.report.rows.forEach(function (val) {
        var bl_100 = $("<table class='block'><tr><td colspan=2 class='dragActive bottom' droppable='true' dropped='true'></td></tr></table>");
        var bl_50 = $("<table class='block'><tr><td class='dragActive left' droppable='true' dropped='false'></td><td class='dragActive right' droppable='true' dropped='false'></td></tr></table>");
        if (val.type === 100) {
          var ui = val.blocks[0].type === 'table' ? 'table' : 'chart_' + val.blocks[0].chart_type;
          if (val.blocks[0].name.length>62)
            var el = $('<div class="table dropped" title="' + ang.translate.instant(ui) + '" draggable="true" clone="false">' + val.blocks[0].name.substring(0,62) + '...' + '<br/>Тип: ' + ang.translate.instant(ui) + '<button title="' + ang.translate.instant('del') + '" class="material-icons close_btn_100" style="color: var(--black-white);">remove</button></div>');
          else
            var el = $('<div class="table dropped" title="' + ang.translate.instant(ui) + '" draggable="true" clone="false">' + val.blocks[0].name + '<br/>Тип: ' + ang.translate.instant(ui) + '<button title="' + ang.translate.instant('del') + '" class="material-icons close_btn_100" style="color: var(--black-white);">remove</button></div>');
          el.addClass(val.blocks[0].type).data('block', val.blocks[0]).on('dblclick',function(){D.edit($(this))}).attr('draggable', true).on('dragstart', function(){D.start($(this))}).on('dragend', function(){D.end()}).on('drag',function() {D.drag(event)});
          el.find('button.close_btn_100').on('click',function(){D.remove($(this))});
          bl_100.find('.bottom').on('dragover', false).on('drop', function(){D.drop(event, $(this))}).on('dragover', function(){D.allow(event, $(this))}).append(el);
          dd.append(bl_100);
        }
        if (val.type === 50) {
          val.blocks.forEach(function (block) {
            var ui = block.type === 'table' ? 'table' : 'chart_' + block.chart_type;
            if (block.name.length>62)
             var el = $('<div class="tb dropped" title="' + ang.translate.instant(ui) + '" draggable="true" clone="false">' + block.name.substring(0,62) + '...' + '<br/>Тип: ' + ang.translate.instant(ui) + '<button title="' + ang.translate.instant('del') + '" class="material-icons close_btn_50" style="color: var(--black-white);">remove</button></div>');
            else
              var el = $('<div class="tb dropped" title="' + ang.translate.instant(ui) + '" draggable="true" clone="false">' + block.name + '<br/>Тип: ' + ang.translate.instant(ui) + '<button title="' + ang.translate.instant('del') + '" class="material-icons close_btn_50" style="color: var(--black-white);">remove</button></div>');
            el.find('button.close_btn_50').on('click',function(){D.remove($(this))});
            el.addClass(block.type).data('block', block).on('dblclick',function(){D.edit($(this))}).attr('draggable', true).on('dragstart', function(){D.start($(this))}).on('dragend', function(){D.end()}).on('drag',function() {D.drag(event)});
            bl_50.find('.' + block.position).attr('dropped','true').on('dragover', false).on('drop', function(){D.drop(event, $(this))}).on('dragover', function(){D.allow(event, $(this))}).append(el);
          });
          dd.append(bl_50);
        }
      });
    //} catch (e) {}
    D.block.find('td[droppable=true]').each(function(){
    });
  }
}($));

